<!--
 * @Description: 分销设置
 * @Author: 琢磨先生
 * @Date: 2022-06-18 03:20:40
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-17 17:35:54
-->

<template>
  <el-card class="box">
    <el-tabs>
      <el-tab-pane label="订房分销设置">
        <edit-house-referral :settings="settings"></edit-house-referral>
      </el-tab-pane>
      <el-tab-pane label="商城分销设置">
        <edit-mall-referral :settings="settings"></edit-mall-referral>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import EditHouseReferral from "./edit_house_referral.vue";
import EditMallReferral from "./edit_mall_referral.vue";
import common_api from "@/http/common_api";
export default {
  components: {
    EditHouseReferral,
    EditMallReferral,
  },
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
      settings: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      common_api.get_settings().then((res) => {
        if (res.code == 0) {
          this.settings = res.data;
          this.form = Object.assign({}, this.settings);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>